import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
  CCard,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESSIBILITY',
            sub: 'バリアフリーのご案内',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/barrier_free/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/barrier_free/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: 'バリアフリーのご案内',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/facilities/barrier_free/img_barrier_free.png',
                  alt: '',
                },
                title: (
                  <>
                    <p className="u_tac u_fs16 u_colorGold">
                      バリアフリールーム
                    </p>
                  </>
                ),
                content: (
                  <>
                    車椅子をご利用のお客様に配慮した客室がございます。
                    <ul className="c_circleList u_mb15">
                      <li>バスルームはスライド式のドア　手すりを設置</li>
                      <li>ベッドの位置が変更可能</li>
                    </ul>
                    <ul className="c_noteList">
                      <li>
                        貸出用の備品もございます。客室係へお申し付けください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/facilities/barrier_free/img_barrier_free02.png',
                  alt: '',
                },
                title: (
                  <>
                    <p className="u_tac u_fs16 u_colorGold">
                      補助犬(盲導犬・介助犬・補導犬)
                    </p>
                  </>
                ),
                content: (
                  <>補助犬(盲導犬・介助犬・補導犬) を受け入れております。</>
                ),
              },
            ]}
          />
          <div>
            <p className="u_mb5 u_fwm">多目的トイレ</p>
            <p>
              ホテル棟：B1F・68F・70F
            </p>
          </div>
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
